/* @media (hover: hover) and (min-width: $widthMobile) */
/* @media (min-width: $widthMobile) */

/* COMMON */

html
  font-size 62.5%
  scroll-behavior smooth

body
  color var(--color-blue)
  background var(--color-white)
  font-family $fontTTFors
  font-size: 1.2rem
  fw(4)
  min-width 32rem
  height 100%
  line-height 1.4


  @media (min-width: 760px)
    font-size: 1.6rem

  @media (min-width: 1080px)
    fs(24)

  @media (min-width: 1800px)
    font-size: 2.4rem

/*
::-webkit-scrollbar
  width .3rem

::-webkit-scrollbar-track
  background: var(--color-bord)

::-webkit-scrollbar-thumb
  background: var(--color-copy)
*/

.cursorWait
  cursor wait

.noscript
  position fixed
  display flex
  justify-content center
  align-items center
  width 100%
  height 100%
  padding 0 2rem
  background-color var(--color-white)
  z-index 999999

.box
  padding-left 2rem
  padding-right 2rem
  width 100%
  margin auto
  max-width: 74.8rem

  @media (min-width: 1080px)
    padding-left 14.2%
    padding-right 14.2%
    max-width: 192rem

  @media (min-width: 1800px)
    padding-left 27.2rem
    padding-right 27.2rem

.title
  font-size: 2.4rem
  
  @media (min-width: 760px)
    font-size: 3.2rem
    text-align: center

  @media (min-width: 1080px)
    fs(44)

  @media (min-width: 1800px)
    font-size: 4.8rem

.text
  ul
    display grid
    flex-direction column
    gap cw(20) 0

    li
      position: relative
      padding-left cw(15)

      &:before
        content '•'
        position: absolute
        left 0
        top 0

.logo
  display: block
  width: 9.2rem

  @media (min-width: 760px)
    width: 15.6rem
    margin: auto

  @media (min-width: $widthMobile)
    width: 15.6rem

  @media (min-width: 1080px)
    width: 20rem

.btn
  display inline-block
  transition all .32s
  cursor pointer
  border-radius: 7.2rem
  border .1rem solid var(--color-blue)
  padding .7rem 2.2rem
  white-space: nowrap
  font-size: 1.2rem
  
  @media (min-width: 760px)
    border-width: .2rem
    font-size: 1.6rem

  @media (min-width: 1080px)
    padding 1.2rem 3.8rem
    fs(28)

  @media (min-width: 1800px)
    font-size: 3.2rem

  &--white
    background var(--color-white)
    border-color var(--color-white)



/* HEADER */

.header
  display grid
  gap 2.4rem
  padding 3.2rem 1.2rem 2.5rem
  width: 100%

  @media (min-width: 760px)
    justify-content center
    gap 3.2rem
    padding 4rem 0 3.2rem

  @media (min-width: 1080px)
    gap 4.8rem
    padding 4.8rem 0 9.6rem

  &__logo
    padding-left .8rem

    @media (min-width: 760px)
      padding-left 0

  &__lang
    position absolute
    top: 3.7rem;
    right 3rem;

    @media (min-width: 760px)
      top: 4.4rem;
      right: 5rem;

    @media (min-width: 1080px)
      right: 6.5rem;

  &__nav
    justify-self: center;

.langText,
.selectLang
  border: 0
  padding: 0 1.8rem 0 0
  color var(--color-blue)
  font-size: 1.2rem
  appearance: none;
  background: url(../images/ico_select-arr.svg) no-repeat right 0 top 50%
  background-size 8px auto
  cursor pointer
  opacity 0
  cursor: pointer

  @media (min-width: 1080px)
    font-size: 1.6rem
    background-size auto

  &.show
    opacity 1

.langText
  position absolute
  opacity 1

.nav
  display: flex
  font-size: 1.2rem

  @media (min-width: 760px)
    font-size: 1.6rem

  @media (min-width: 1080px)
    fs(24)

  &__item
    border .1rem solid var(--color-blue)
    border-left-width 0
    border-right-width 0
    position: relative
    transition: .32s
  
    @media (min-width: 760px)
      border-top-width: .2rem
      border-bottom-width: .2rem

    &:nth-child(n+2):before
      content: ''
      position: absolute
      left 0
      top 1rem
      bottom: 1rem
      width: .1rem
      background: var(--color-blue)
      opacity .6
      transform: translate(-50%, 0)
  
      @media (min-width: 760px)
        width: .2rem
        top .6rem
        bottom: .6rem

      @media (min-width: 1080px)
        top 1.2rem
        bottom: 1.2rem

    &:first-child
      border-radius 3rem 0 0 3rem
      border-left-width .1rem
  
      @media (min-width: 760px)
        border-left-width: .2rem

    &:last-child
      border-radius 0 3rem 3rem 0
      border-right-width .1rem
  
      @media (min-width: 760px)
        border-right-width .2rem

  &__link
    display: block
    padding 1.6rem 1.2rem

    @media (min-width: 760px)
      padding .7rem 2.6rem

    @media (min-width: 1080px)
      padding 1.1rem 3.4rem



/* FOOTER */

.footer
  padding 0 2rem 
  max-width: 74.8rem

  @media (min-width: 1080px)
    padding-left 14.2%
    padding-right 14.2%
    max-width: 192rem

  @media (min-width: 1800px)
    padding-left 27.2rem
    padding-right 27.2rem
  
  &__box
    padding 2.6rem 0 3.2rem
    border-top .1rem solid rgba(5, 70, 180, .2)
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
    gap 2rem
    max-width: 71.2rem
    margin auto

    @media (min-width: 760px)
      padding 4.8rem 0
      gap 1.3rem

    @media (min-width: 1080px)
      padding 9.2rem 0
      max-width: 137.6rem

  &__links
    width: 100%

  &__soc
    order 1
    padding: 1.5rem 0 0

    @media (min-width: 760px)
      padding 0
      order inherit

  &__copyright
    max-width 17rem

    @media (min-width: 760px)
      max-width 22rem

    @media (min-width: 1080px)
      max-width none

  &Links
    display: flex
    gap 1.8rem 5.8rem
    white-space: nowrap;
    flex-wrap: wrap
    overflow: hidden;
    flex-direction: column
    align-items flex-start
    
    @media (min-width: 760px)
      gap 1.6rem 5.8rem
      flex-direction: row

    &__item
      @media (min-width: 760px)
        position relative

      &:nth-child(n+2):before
        @media (min-width: 760px)
          content ''
          position absolute
          left -2.9rem
          top 50%
          transform translate(-50%, -50%)
          width .3rem
          height .3rem
          background: var(--color-blue)
          border-radius: 50%

    &__link
      display: block
      line-height: 1
      border-bottom: .1rem solid var(--color-blue)
      transition: .32s

      @media (min-width: 1080px)
        line-height: 1.1
        border-bottom-width .2rem

      &:hover
        @media (hover: hover) and (min-width: $widthMobile)
          border-color transparent

.soc
  display flex
  gap 3.1rem

  @media (min-width: 1080px)
    gap 3.2rem

  &__item
    @media (min-width: $widthMobile)
      transition: .32s

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        transform: scale(1.2)

  &__img
    max-height: 1.6rem

    @media (min-width: 1080px)
      max-height none

.popup
  display none

  &__wrap
    max-width: 82rem

  &__title
    padding: 0 0 1.8rem



/* CONTENT */

.content
  position relative
  padding 0 0 4.2rem

  @media (min-width: 760px)
    padding 0 0 5.4rem

  @media (min-width: 1080px)
    padding 0 0 9.4rem

  &__section
    &:nth-child(n+2)
      padding-top 2.2rem

      @media (min-width: 760px)
        padding-top 4rem

      @media (min-width: 1080px)
        padding-top 9.6rem

.first
  &__box
    display grid
    align-items: stretch;
    gap .8rem
    font-size: 1.2rem
    padding 0
    max-width none

    @media (min-width: 760px)
      grid-template-columns: repeat(2, 1fr)
      font-size: 1.6rem
      gap 1.2rem

    @media (min-width: 1080px)
      max-width 196rem
      padding 0
      gap 3.2rem
      fs(28)

    @media (min-width: 1800px)
      font-size: 3.2rem
      padding 0

    @media (min-width: 1921px)
      padding-left 2rem
      padding-right 2rem
      
  &__left
    object-fit: cover;

  &__right
    background var(--color-green)
    padding 3.8rem 2.8rem

    @media (min-width: 800px)
      padding 3.8rem 3.4rem

    @media (min-width: 1080px)
      padding cw(60) cw(40)

    @media (min-width: 1800px)
      padding 9rem 8.2rem

  &__title
    padding: 0 0 1.5rem

    @media (min-width: 760px)
      text-align left

    @media (min-width: 1080px)
      padding: 0 0 2.5rem

  &__text
    display: grid
    gap 1.6rem
    padding: 0 0 3rem

    @media (min-width: 1080px)
      padding: 0 0 cw(60)
      gap 2.2rem

    @media (min-width: 1800px)
      padding: 0 0 8.5rem

  &__btns
    display: flex
    flex-direction: column;
    align-items: flex-start;
    gap 1.2rem

    @media (min-width: 1800px)
      gap 2.4rem

.actions,
.advantages,
.tehnologies
  &__box
    padding: 0

    @media (min-width: 760px)
      padding-left 2rem
      padding-right 2rem

  &__title
    padding: 0 2rem

  &__items
    display grid
    gap .8rem
    padding 1.2rem 0 0

    @media (min-width: 760px)
      grid-template-columns: repeat(2, 1fr)
      gap 1.2rem
      padding: 2rem 0 0

    @media (min-width: 1080px)
      padding 4.4rem 0 0
      gap 3.2rem

.tehnologies
  &__items
    @media (min-width: 1080px)
      padding 4.2rem 0 0

.advantage
  background: var(--color-blue)
  color #fff
  text-align: center
  padding 3.6rem
  display: flex
  flex-direction: column
  align-items: center
  justify-content center
  gap cw(26)

  @media (min-width: 760px)
    aspect-ratio: 1
    padding: cw(40)

  &__img
    max-height 7rem

    @media (min-width: 1080px)
      max-height none

  &__title
    fs(32)

  &__text
    br
      display: none

      @media (min-width: 1080px)
        display: block

.action
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  gap 1.2rem
  padding-bottom: 1.2rem

  @media (min-width: 1080px)
    padding-bottom: 1.4rem

  &__title
    fs(32)
    padding .4rem 0 0

    @media (min-width: 760px)
      padding 1rem 0 0

    @media (min-width: 1080px)
      padding 1.6rem 0 1rem

    @media (min-width: 1800px)
      padding 1.4rem 0 1rem

.tehnology
  @media (min-width: 760px)
    padding-bottom 1.2rem

  @media (min-width: 1080px)
    padding-bottom 0

  &__text
    padding: 1rem 2rem 0

    @media (min-width: 760px)
      padding: 0

.characteristics
  padding-bottom 1.8rem

  @media (min-width: 760px)
    padding-bottom 0

  &__sheet
    display: grid
    grid-template-columns: 51% auto
    padding: .7rem 0 0

    @media (min-width: 760px)
      padding: .5rem 0 0

    @media (min-width: 1080px)
      padding: 1.8rem 0 0

    dt, dd
      padding 1.7rem 0
      border-bottom: .1rem solid rgba(5, 70, 180, .2)

      @media (min-width: 1080px)
        padding 3.2rem 0

    dt
      padding-right: 2rem

  &__btns
    display flex
    justify-content center
    border-bottom .1rem solid rgba(5, 70, 180, .2)
    padding 1.6rem
    margin-bottom 2.6rem

    @media (min-width: 1080px)
      padding 3.2rem
      margin-bottom 4.8rem

.ask
  padding-bottom 3.4rem

  @media (min-width: 760px)
    padding-bottom 0

  &__text
    padding 1rem 0 0

    @media (min-width: 760px)
      padding 2.4rem 0 0

    @media (min-width: 1080px)
      padding 4.8rem 0 0

    a
      text-decoration: underline

.buy
  &__items
    padding 2.4rem 2rem 0
    display: flex
    flex-wrap wrap
    justify-content center
    align-items center
    gap 2rem 20%

    @media (min-width: 760px)
      padding 2.4rem 0 0
      flex-wrap: nowrap
      gap 3.6rem 6%

    @media (min-width: 1080px)
      padding 5.8rem 0 0
      max-height none

  &__item
    width: 40%

    @media (min-width: 760px)
      width auto

.gallery
  padding 0 0 1.8rem

  &__box
    display grid
    gap .8rem
    padding 0

    @media (min-width: 760px)
      align-items: start;
      grid-template-columns: repeat(2, 1fr)
      gap 1.2rem
      padding 0 2rem

    @media (min-width: 1080px)
      gap 3.2rem

    @media (min-width: 1800px)
      padding 0 9.8rem

  &__title
    padding: 3.8rem 2rem 1rem

    @media (min-width: 760px)
      padding: 0 0 1rem
      grid-column: 1/3

    @media (min-width: 1080px)
      padding: 0

  &__imgMain
    width 100%

  &__slider
    display grid
    grid-template-columns: repeat(4, 1fr)
    gap 1.2rem
    padding 0 2rem 1.4rem

    @media (min-width: 760px)
      padding 0
    
    @media (min-width: 1800px)
      grid-template-columns auto
      grid-column 1/2
      grid-row 1/2
      gap 3.2rem

    &Wrap
      display grid
      gap 1.2rem

      @media (min-width: 1800px)
        grid-template-columns 16.9% auto
        gap 3.2rem

  &__img
    transition: .32s

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        box-shadow: 0 0 0 2px rgba(5, 70, 180, .5)
        cursor pointer

    &.active:hover,
    &.active
      box-shadow: 0 0 0 2px rgba(5, 70, 180, 1)
      cursor default

  &__text
    display: flex
    flex-direction: column
    gap 1.2rem
    padding 0 2rem

    @media (min-width: 760px)
      gap 3.2rem
      padding 0

    @media (min-width: 1580px)
      padding 0 11% 0 0

    @media (min-width: 1800px)
      padding 0 22% 0 0

    ul
      display: flex
      flex-direction: column
      gap 1.2rem

      @media (min-width: 760px)
        gap 2rem

      @media (min-width: 1080px)
        gap 2.4rem

    li
      padding 0 0 0 2rem
      position relative

      @media (min-width: 760px)
        padding 0 0 0 2.3rem

      @media (min-width: 1080px)
        padding 0 0 0 3.4rem

      &:before
        content '•'
        position: absolute
        left .8rem
        line-height: 1.2

        @media (min-width: 760px)
          left .8rem

        @media (min-width: 1080px)
          left 1.2rem
