:root {
  --color-white: #fff;
  --color-blue: #0546b4;
  --color-green: #aae446;
}
@font-face {
  font-display: swap;
  font-family: 'TT Fors';
  font-style: normal;
  font-weight: 400;
  src: local(''), url("../fonts/tt-fors_medium.woff2") format('woff2');
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  will-change: all;
}
*:before,
*:after {
  box-sizing: border-box;
  will-change: all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-focus-ring-color: rgba(255,255,255,0);
}
body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a {
  background: transparent;
}
*:focus,
*:active,
*:hover {
  outline: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
}
ol,
ul {
  list-style: none;
}
pre {
  tab-size: 4;
  white-space: pre-wrap;
}
q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="phone"] {
  -webkit-appearance: none;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
button,
input,
select[multiple],
textarea {
  background-image: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
input,
textarea {
  resize: none;
  user-select: text;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  height: 100%;
}
.hide {
  display: none;
}
.hidden {
  overflow: hidden;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}
.nobr {
  white-space: nowrap;
}
.italic {
  font-style: italic;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
strong,
b {
  font-weight: 700;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  color: var(--color-blue);
  background: var(--color-white);
  font-family: 'TT Fors', Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  min-width: 32rem;
  height: 100%;
  line-height: 1.4;
}
@media (min-width: 760px) {
  body {
    font-size: 1.6rem;
  }
}
@media (min-width: 1080px) {
  body {
    font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
  }
}
@media (min-width: 1800px) {
  body {
    font-size: 2.4rem;
  }
}
.cursorWait {
  cursor: wait;
}
.noscript {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  background-color: var(--color-white);
  z-index: 999999;
}
.box {
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  margin: auto;
  max-width: 74.8rem;
}
@media (min-width: 1080px) {
  .box {
    padding-left: 14.2%;
    padding-right: 14.2%;
    max-width: 192rem;
  }
}
@media (min-width: 1800px) {
  .box {
    padding-left: 27.2rem;
    padding-right: 27.2rem;
  }
}
.title {
  font-size: 2.4rem;
}
@media (min-width: 760px) {
  .title {
    font-size: 3.2rem;
    text-align: center;
  }
}
@media (min-width: 1080px) {
  .title {
    font-size: min(calc(1.4rem + 1.56vw), 4.4rem);
  }
}
@media (min-width: 1800px) {
  .title {
    font-size: 4.8rem;
  }
}
.text ul {
  display: grid;
  flex-direction: column;
  gap: min(calc(1rem + 0.52vw), 2rem) 0;
}
.text ul li {
  position: relative;
  padding-left: min(calc(0.75rem + 0.39vw), 1.5rem);
}
.text ul li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.logo {
  display: block;
  width: 9.2rem;
}
@media (min-width: 760px) {
  .logo {
    width: 15.6rem;
    margin: auto;
  }
}
@media (min-width: 992px) {
  .logo {
    width: 15.6rem;
  }
}
@media (min-width: 1080px) {
  .logo {
    width: 20rem;
  }
}
.btn {
  display: inline-block;
  transition: all 0.32s;
  cursor: pointer;
  border-radius: 7.2rem;
  border: 0.1rem solid var(--color-blue);
  padding: 0.7rem 2.2rem;
  white-space: nowrap;
  font-size: 1.2rem;
}
@media (min-width: 760px) {
  .btn {
    border-width: 0.2rem;
    font-size: 1.6rem;
  }
}
@media (min-width: 1080px) {
  .btn {
    padding: 1.2rem 3.8rem;
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  }
}
@media (min-width: 1800px) {
  .btn {
    font-size: 3.2rem;
  }
}
.btn--white {
  background: var(--color-white);
  border-color: var(--color-white);
}
.header {
  display: grid;
  gap: 2.4rem;
  padding: 3.2rem 1.2rem 2.5rem;
  width: 100%;
}
@media (min-width: 760px) {
  .header {
    justify-content: center;
    gap: 3.2rem;
    padding: 4rem 0 3.2rem;
  }
}
@media (min-width: 1080px) {
  .header {
    gap: 4.8rem;
    padding: 4.8rem 0 9.6rem;
  }
}
.header__logo {
  padding-left: 0.8rem;
}
@media (min-width: 760px) {
  .header__logo {
    padding-left: 0;
  }
}
.header__lang {
  position: absolute;
  top: 3.7rem;
  right: 3rem;
}
@media (min-width: 760px) {
  .header__lang {
    top: 4.4rem;
    right: 5rem;
  }
}
@media (min-width: 1080px) {
  .header__lang {
    right: 6.5rem;
  }
}
.header__nav {
  justify-self: center;
}
.langText,
.selectLang {
  border: 0;
  padding: 0 1.8rem 0 0;
  color: var(--color-blue);
  font-size: 1.2rem;
  appearance: none;
  background: url("../images/ico_select-arr.svg") no-repeat right 0 top 50%;
  background-size: 8px auto;
  cursor: pointer;
  opacity: 0;
  cursor: pointer;
}
@media (min-width: 1080px) {
  .langText,
  .selectLang {
    font-size: 1.6rem;
    background-size: auto;
  }
}
.langText.show,
.selectLang.show {
  opacity: 1;
}
.langText {
  position: absolute;
  opacity: 1;
}
.nav {
  display: flex;
  font-size: 1.2rem;
}
@media (min-width: 760px) {
  .nav {
    font-size: 1.6rem;
  }
}
@media (min-width: 1080px) {
  .nav {
    font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
  }
}
.nav__item {
  border: 0.1rem solid var(--color-blue);
  border-left-width: 0;
  border-right-width: 0;
  position: relative;
  transition: 0.32s;
}
@media (min-width: 760px) {
  .nav__item {
    border-top-width: 0.2rem;
    border-bottom-width: 0.2rem;
  }
}
.nav__item:nth-child(n+2):before {
  content: '';
  position: absolute;
  left: 0;
  top: 1rem;
  bottom: 1rem;
  width: 0.1rem;
  background: var(--color-blue);
  opacity: 0.6;
  transform: translate(-50%, 0);
}
@media (min-width: 760px) {
  .nav__item:nth-child(n+2):before {
    width: 0.2rem;
    top: 0.6rem;
    bottom: 0.6rem;
  }
}
@media (min-width: 1080px) {
  .nav__item:nth-child(n+2):before {
    top: 1.2rem;
    bottom: 1.2rem;
  }
}
.nav__item:first-child {
  border-radius: 3rem 0 0 3rem;
  border-left-width: 0.1rem;
}
@media (min-width: 760px) {
  .nav__item:first-child {
    border-left-width: 0.2rem;
  }
}
.nav__item:last-child {
  border-radius: 0 3rem 3rem 0;
  border-right-width: 0.1rem;
}
@media (min-width: 760px) {
  .nav__item:last-child {
    border-right-width: 0.2rem;
  }
}
.nav__link {
  display: block;
  padding: 1.6rem 1.2rem;
}
@media (min-width: 760px) {
  .nav__link {
    padding: 0.7rem 2.6rem;
  }
}
@media (min-width: 1080px) {
  .nav__link {
    padding: 1.1rem 3.4rem;
  }
}
.footer {
  padding: 0 2rem;
  max-width: 74.8rem;
}
@media (min-width: 1080px) {
  .footer {
    padding-left: 14.2%;
    padding-right: 14.2%;
    max-width: 192rem;
  }
}
@media (min-width: 1800px) {
  .footer {
    padding-left: 27.2rem;
    padding-right: 27.2rem;
  }
}
.footer__box {
  padding: 2.6rem 0 3.2rem;
  border-top: 0.1rem solid rgba(5,70,180,0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 71.2rem;
  margin: auto;
}
@media (min-width: 760px) {
  .footer__box {
    padding: 4.8rem 0;
    gap: 1.3rem;
  }
}
@media (min-width: 1080px) {
  .footer__box {
    padding: 9.2rem 0;
    max-width: 137.6rem;
  }
}
.footer__links {
  width: 100%;
}
.footer__soc {
  order: 1;
  padding: 1.5rem 0 0;
}
@media (min-width: 760px) {
  .footer__soc {
    padding: 0;
    order: inherit;
  }
}
.footer__copyright {
  max-width: 17rem;
}
@media (min-width: 760px) {
  .footer__copyright {
    max-width: 22rem;
  }
}
@media (min-width: 1080px) {
  .footer__copyright {
    max-width: none;
  }
}
.footerLinks {
  display: flex;
  gap: 1.8rem 5.8rem;
  white-space: nowrap;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 760px) {
  .footerLinks {
    gap: 1.6rem 5.8rem;
    flex-direction: row;
  }
}
@media (min-width: 760px) {
  .footerLinks__item {
    position: relative;
  }
}
@media (min-width: 760px) {
  .footerLinks__item:nth-child(n+2):before {
    content: '';
    position: absolute;
    left: -2.9rem;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.3rem;
    height: 0.3rem;
    background: var(--color-blue);
    border-radius: 50%;
  }
}
.footerLinks__link {
  display: block;
  line-height: 1;
  border-bottom: 0.1rem solid var(--color-blue);
  transition: 0.32s;
}
@media (min-width: 1080px) {
  .footerLinks__link {
    line-height: 1.1;
    border-bottom-width: 0.2rem;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .footerLinks__link:hover {
    border-color: transparent;
  }
}
.soc {
  display: flex;
  gap: 3.1rem;
}
@media (min-width: 1080px) {
  .soc {
    gap: 3.2rem;
  }
}
@media (min-width: 992px) {
  .soc__item {
    transition: 0.32s;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .soc__item:hover {
    transform: scale(1.2);
  }
}
.soc__img {
  max-height: 1.6rem;
}
@media (min-width: 1080px) {
  .soc__img {
    max-height: none;
  }
}
.popup {
  display: none;
}
.popup__wrap {
  max-width: 82rem;
}
.popup__title {
  padding: 0 0 1.8rem;
}
.content {
  position: relative;
  padding: 0 0 4.2rem;
}
@media (min-width: 760px) {
  .content {
    padding: 0 0 5.4rem;
  }
}
@media (min-width: 1080px) {
  .content {
    padding: 0 0 9.4rem;
  }
}
.content__section:nth-child(n+2) {
  padding-top: 2.2rem;
}
@media (min-width: 760px) {
  .content__section:nth-child(n+2) {
    padding-top: 4rem;
  }
}
@media (min-width: 1080px) {
  .content__section:nth-child(n+2) {
    padding-top: 9.6rem;
  }
}
.first__box {
  display: grid;
  align-items: stretch;
  gap: 0.8rem;
  font-size: 1.2rem;
  padding: 0;
  max-width: none;
}
@media (min-width: 760px) {
  .first__box {
    grid-template-columns: repeat(2, 1fr);
    font-size: 1.6rem;
    gap: 1.2rem;
  }
}
@media (min-width: 1080px) {
  .first__box {
    max-width: 196rem;
    padding: 0;
    gap: 3.2rem;
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  }
}
@media (min-width: 1800px) {
  .first__box {
    font-size: 3.2rem;
    padding: 0;
  }
}
@media (min-width: 1921px) {
  .first__box {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.first__left {
  object-fit: cover;
}
.first__right {
  background: var(--color-green);
  padding: 3.8rem 2.8rem;
}
@media (min-width: 800px) {
  .first__right {
    padding: 3.8rem 3.4rem;
  }
}
@media (min-width: 1080px) {
  .first__right {
    padding: min(calc(3rem + 1.56vw), 6rem) min(calc(2rem + 1.04vw), 4rem);
  }
}
@media (min-width: 1800px) {
  .first__right {
    padding: 9rem 8.2rem;
  }
}
.first__title {
  padding: 0 0 1.5rem;
}
@media (min-width: 760px) {
  .first__title {
    text-align: left;
  }
}
@media (min-width: 1080px) {
  .first__title {
    padding: 0 0 2.5rem;
  }
}
.first__text {
  display: grid;
  gap: 1.6rem;
  padding: 0 0 3rem;
}
@media (min-width: 1080px) {
  .first__text {
    padding: 0 0 min(calc(3rem + 1.56vw), 6rem);
    gap: 2.2rem;
  }
}
@media (min-width: 1800px) {
  .first__text {
    padding: 0 0 8.5rem;
  }
}
.first__btns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
}
@media (min-width: 1800px) {
  .first__btns {
    gap: 2.4rem;
  }
}
.actions__box,
.advantages__box,
.tehnologies__box {
  padding: 0;
}
@media (min-width: 760px) {
  .actions__box,
  .advantages__box,
  .tehnologies__box {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.actions__title,
.advantages__title,
.tehnologies__title {
  padding: 0 2rem;
}
.actions__items,
.advantages__items,
.tehnologies__items {
  display: grid;
  gap: 0.8rem;
  padding: 1.2rem 0 0;
}
@media (min-width: 760px) {
  .actions__items,
  .advantages__items,
  .tehnologies__items {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
    padding: 2rem 0 0;
  }
}
@media (min-width: 1080px) {
  .actions__items,
  .advantages__items,
  .tehnologies__items {
    padding: 4.4rem 0 0;
    gap: 3.2rem;
  }
}
@media (min-width: 1080px) {
  .tehnologies__items {
    padding: 4.2rem 0 0;
  }
}
.advantage {
  background: var(--color-blue);
  color: #fff;
  text-align: center;
  padding: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: min(calc(1.3rem + 0.68vw), 2.6rem);
}
@media (min-width: 760px) {
  .advantage {
    aspect-ratio: 1;
    padding: min(calc(2rem + 1.04vw), 4rem);
  }
}
.advantage__img {
  max-height: 7rem;
}
@media (min-width: 1080px) {
  .advantage__img {
    max-height: none;
  }
}
.advantage__title {
  font-size: min(calc(1.4rem + 0.94vw), 3.2rem);
}
.advantage__text br {
  display: none;
}
@media (min-width: 1080px) {
  .advantage__text br {
    display: block;
  }
}
.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.2rem;
  padding-bottom: 1.2rem;
}
@media (min-width: 1080px) {
  .action {
    padding-bottom: 1.4rem;
  }
}
.action__title {
  font-size: min(calc(1.4rem + 0.94vw), 3.2rem);
  padding: 0.4rem 0 0;
}
@media (min-width: 760px) {
  .action__title {
    padding: 1rem 0 0;
  }
}
@media (min-width: 1080px) {
  .action__title {
    padding: 1.6rem 0 1rem;
  }
}
@media (min-width: 1800px) {
  .action__title {
    padding: 1.4rem 0 1rem;
  }
}
@media (min-width: 760px) {
  .tehnology {
    padding-bottom: 1.2rem;
  }
}
@media (min-width: 1080px) {
  .tehnology {
    padding-bottom: 0;
  }
}
.tehnology__text {
  padding: 1rem 2rem 0;
}
@media (min-width: 760px) {
  .tehnology__text {
    padding: 0;
  }
}
.characteristics {
  padding-bottom: 1.8rem;
}
@media (min-width: 760px) {
  .characteristics {
    padding-bottom: 0;
  }
}
.characteristics__sheet {
  display: grid;
  grid-template-columns: 51% auto;
  padding: 0.7rem 0 0;
}
@media (min-width: 760px) {
  .characteristics__sheet {
    padding: 0.5rem 0 0;
  }
}
@media (min-width: 1080px) {
  .characteristics__sheet {
    padding: 1.8rem 0 0;
  }
}
.characteristics__sheet dt,
.characteristics__sheet dd {
  padding: 1.7rem 0;
  border-bottom: 0.1rem solid rgba(5,70,180,0.2);
}
@media (min-width: 1080px) {
  .characteristics__sheet dt,
  .characteristics__sheet dd {
    padding: 3.2rem 0;
  }
}
.characteristics__sheet dt {
  padding-right: 2rem;
}
.characteristics__btns {
  display: flex;
  justify-content: center;
  border-bottom: 0.1rem solid rgba(5,70,180,0.2);
  padding: 1.6rem;
  margin-bottom: 2.6rem;
}
@media (min-width: 1080px) {
  .characteristics__btns {
    padding: 3.2rem;
    margin-bottom: 4.8rem;
  }
}
.ask {
  padding-bottom: 3.4rem;
}
@media (min-width: 760px) {
  .ask {
    padding-bottom: 0;
  }
}
.ask__text {
  padding: 1rem 0 0;
}
@media (min-width: 760px) {
  .ask__text {
    padding: 2.4rem 0 0;
  }
}
@media (min-width: 1080px) {
  .ask__text {
    padding: 4.8rem 0 0;
  }
}
.ask__text a {
  text-decoration: underline;
}
.buy__items {
  padding: 2.4rem 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem 20%;
}
@media (min-width: 760px) {
  .buy__items {
    padding: 2.4rem 0 0;
    flex-wrap: nowrap;
    gap: 3.6rem 6%;
  }
}
@media (min-width: 1080px) {
  .buy__items {
    padding: 5.8rem 0 0;
    max-height: none;
  }
}
.buy__item {
  width: 40%;
}
@media (min-width: 760px) {
  .buy__item {
    width: auto;
  }
}
.gallery {
  padding: 0 0 1.8rem;
}
.gallery__box {
  display: grid;
  gap: 0.8rem;
  padding: 0;
}
@media (min-width: 760px) {
  .gallery__box {
    align-items: start;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
    padding: 0 2rem;
  }
}
@media (min-width: 1080px) {
  .gallery__box {
    gap: 3.2rem;
  }
}
@media (min-width: 1800px) {
  .gallery__box {
    padding: 0 9.8rem;
  }
}
.gallery__title {
  padding: 3.8rem 2rem 1rem;
}
@media (min-width: 760px) {
  .gallery__title {
    padding: 0 0 1rem;
    grid-column: 1/3;
  }
}
@media (min-width: 1080px) {
  .gallery__title {
    padding: 0;
  }
}
.gallery__imgMain {
  width: 100%;
}
.gallery__slider {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;
  padding: 0 2rem 1.4rem;
}
@media (min-width: 760px) {
  .gallery__slider {
    padding: 0;
  }
}
@media (min-width: 1800px) {
  .gallery__slider {
    grid-template-columns: auto;
    grid-column: 1/2;
    grid-row: 1/2;
    gap: 3.2rem;
  }
}
.gallery__sliderWrap {
  display: grid;
  gap: 1.2rem;
}
@media (min-width: 1800px) {
  .gallery__sliderWrap {
    grid-template-columns: 16.9% auto;
    gap: 3.2rem;
  }
}
.gallery__img {
  transition: 0.32s;
}
@media (hover: hover) and (min-width: 992px) {
  .gallery__img:hover {
    box-shadow: 0 0 0 2px rgba(5,70,180,0.5);
    cursor: pointer;
  }
}
.gallery__img.active:hover,
.gallery__img.active {
  box-shadow: 0 0 0 2px #0546b4;
  cursor: default;
}
.gallery__text {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 0 2rem;
}
@media (min-width: 760px) {
  .gallery__text {
    gap: 3.2rem;
    padding: 0;
  }
}
@media (min-width: 1580px) {
  .gallery__text {
    padding: 0 11% 0 0;
  }
}
@media (min-width: 1800px) {
  .gallery__text {
    padding: 0 22% 0 0;
  }
}
.gallery__text ul {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
@media (min-width: 760px) {
  .gallery__text ul {
    gap: 2rem;
  }
}
@media (min-width: 1080px) {
  .gallery__text ul {
    gap: 2.4rem;
  }
}
.gallery__text li {
  padding: 0 0 0 2rem;
  position: relative;
}
@media (min-width: 760px) {
  .gallery__text li {
    padding: 0 0 0 2.3rem;
  }
}
@media (min-width: 1080px) {
  .gallery__text li {
    padding: 0 0 0 3.4rem;
  }
}
.gallery__text li:before {
  content: '•';
  position: absolute;
  left: 0.8rem;
  line-height: 1.2;
}
@media (min-width: 760px) {
  .gallery__text li:before {
    left: 0.8rem;
  }
}
@media (min-width: 1080px) {
  .gallery__text li:before {
    left: 1.2rem;
  }
}
