// a - необходимое значение
// b - значение родительского блока
cp(a, b) //countPersent
  a = unit(a, px)
  b = unit(b, px)
  c = a / b * 100
  return unit(c, '%')

fw(s) //fontWeight
  font-weight "%s00" % (s)

fs(s) //fontSize
  f = 14
  a = (s - f) / 1920 * 100
  a = round(a,2)
  a = (a)vw
  f = f / 10
  f = (f)rem
  s = s / 10
  s = (s)rem
  font-size "min(calc(%s + %s), %s)" % (f a s)

cw(s) //countVW
  f = s / 2 / 10
  f = (f)rem
  a = s / 2 / 1920 * 100
  a = round(a,2)
  a = (a)vw
  s = s / 10
  s = (s)rem
  return "min(calc(%s + %s), %s)" % (f a s)